'use strict';
// tooltip, popover , toast etc..
$(document).on('turbolinks:load', function() {
    toolTip();
    coloredPopOver();
    noLinkHref();
    togglePassword();
    $('.toast').toast('show');
    $('[data-toggle="popover"]').popover({
        trigger: 'focus',
        boundary: 'window'
    });
    $('[data-toggle="hover-popover"]').popover({
        trigger: 'hover',
        boundary: 'window'
    });
});

const toolTip = function(){
    let $tooltip = $('[data-toggle="tooltip"]');
    if ($tooltip.length) {
        $tooltip.tooltip();
    }
}

const noLinkHref = function () {
    // Prevent refreshing page on click of href= '#' calls
    $('.no-link-href').click(function(e){  e.preventDefault(); });
}

const coloredPopOver = function(){
    let $popover = $('[data-toggle="colored-popover"]');
    let popoverClass = 'popover-default';
    let popoverTrigger = 'focus'; // default
    // Methods
    function init($this) {
        if ($this.data('color')) {
            popoverClass = 'popover-' + $this.data('color');
        }
        if ($this.data('trigger')) {
            popoverTrigger =$this.data('trigger')
        }
        let options = {
            trigger: popoverTrigger,
            // fix for -> popover|tooltip flickers when placed on top of button, but works fine when placed at the left/right/bottom
            boundary: 'window',
            template: '<div class="popover ' + popoverClass + '" role="tooltip"><div class="arrow"></div><h3 class="popover-header"></h3><div class="popover-body"></div></div>'
        };
        $this.popover(options);
    }
    // Events
    if ($popover.length) {
        $popover.each(function() {
            init($(this));
        });
    }
};

const togglePassword = function(){
    $(".toggle-password").click(function() {
        $(this).toggleClass("fa-eye fa-eye-slash");
        let input = $($(this).attr("toggle"));
        if (input.attr("type") === "password") {
            input.attr("type", "text");
        } else {
            input.attr("type", "password");
        }
    });
}

// To use in js.erb file when requests from AJAX calls
module.exports = { coloredPopOver, toolTip, noLinkHref };