$(document).on('turbolinks:load', function () {
    let leadForm = $('#lead-form');
    leadForm.validate({
        rules: {
            'lead[email]': {
                required: true
            }
        },
        messages: {
            'lead[email]': {
                required: 'Please enter Email'
            }
        }
    })
});