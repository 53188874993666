$(document).on('turbolinks:load', function () {
    let loginForm = $('#login-form');
    loginForm.validate({
        rules: {
            'user[email]': {
                required: true
            },
            'user[password]': {
                required: true
            }
        },
        messages: {
            'user[email]': {
                required: 'Please enter Email'
            },
            'user[password]': {
                required: 'Please enter Password'
            }
        }
    })
});