// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
// require("@rails/activestorage").start()
// require("channels")

// import Trix editor and Action Text
//import 'trix';
//import '@rails/actiontext';
import 'trix/dist/trix.css';
import '../js/action-text'

import '../css/application'
// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
const images = require.context('../images', true);
const imagePath = (name) => images(name, true);
// ==> Jquery Related
let jQuery = require("jquery");
// import jQuery from "jquery";
global.$ = jQuery;
//==> Jquery related plugins goes here

//==> Bootstrap related plugins goes here
import 'bootstrap';
import 'select2';
import 'croppie';
import 'cocoon-js'
//==> js core functionalities including custom plugins settings at one place
import '../js/core/index'

//==> Application related js files goes here
// import '../js/hello_erb.js.erb';
// import '../js/hello_coffee.coffee';
import '../js/main';
import '../js/home';
import '../js/crop_avatar';
// ==> Validations
import '../js/validations/contact_us'
import '../js/validations/signup_form'
import '../js/validations/forgot_password'
import '../js/validations/reset_password'
import '../js/validations/login'
import '../js/validations/password'
import '../js/validations/joint_venture_form'
import '../js/validations/support_form'
import '../js/validations/lead_form'
import '../js/validations/blog/post_form'

import smoothscroll from 'smoothscroll-polyfill';
// initialise smoothscroll
smoothscroll.polyfill();
