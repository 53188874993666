$(document).on('turbolinks:load', function () {
    let contact_us_form = $('#contact-us-form');
    contact_us_form.validate({
        rules: {
            'contact_us[name]': {
                required: true
            },
            'contact_us[email]': {
                required: true,
                email_format: true
            },
            'contact_us[phone_number]': {
                required: true
            },
            'contact_us[reason]': {
                required: true
            },
            'contact_us[message]': {
                required: true
            }
        },
        messages: {
            'contact_us[name]': {
                required: 'Please enter Name'
            },
            'contact_us[email]': {
                required: 'Please enter Email'
            },
            'contact_us[phone_number]': {
                required: 'Please enter Phone number'
            },
            'contact_us[reason]': {
                required: 'Please enter Reason'
            },
            'contact_us[message]': {
                required: 'Please enter Message'
            }
        }
    })
});