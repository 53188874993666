$(document).on('turbolinks:load', function () {
    let contact_us_form = $('#reset-pass-form');
    contact_us_form.validate({
        rules: {
            'user[password]': {
                required: true
            },
            'user[password_confirmation]': {
                required: true,
                equalTo: '#password-field'
            }
        },
        messages: {
            'user[password]': {
                required: 'Please enter Password',
            },
            'user[password_confirmation]': {
                required: 'Please enter Confirmation Password',
                equalTo: "Password and Confirmation password doesn't match"
            }
        }
    })
});