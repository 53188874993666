'use strict';
import iziToast from 'izitoast/dist/js/iziToast';
// Showing form errors and flash messages using iziToast
iziToast.settings({
    position: 'topCenter', // bottomRight, bottomLeft, topRight, topLeft, topCenter, bottomCenter
    timeout: 20000, // default 5000
    resetOnHover: true,
    transitionIn: 'flipInX',
    transitionOut: 'flipOutX',
    transitionInMobile: 'fadeInUp',
    transitionOutMobile: 'fadeOutDown',
    animateInside: true,
    drag: true,
    // Waits until the toast is closed so you can open it (Use 1 or 'once').
    // Replaces the toast that was already open (Use 2 or 'replace')
    displayMode: 2
    //overlay: true,
    //overlayClose: true
});