$(document).on('turbolinks:load', function () {
    let $user_avatar;
    // On click on of choose picture button click file input field
    $('#change-profile-picture').on('click', function (e) {
        e.preventDefault();
        $('#user-avatar-input-file').trigger('click');
    });
    // convert file to base64 and assign to form hidden field
    $('#user-avatar-input-file').on('change', function () {
        if (this.files && this.files[0]) {
            let file = this.files[0];
            readFile(file);
        }
    });
    // For image rotation
    $('#rotate-left').on('click', function () {
        $user_avatar.croppie('rotate', parseInt($('#rotate-left').data('deg')));
    });
    $('#rotate-right').on('click', function () {
        $user_avatar.croppie('rotate', parseInt($('#rotate-right').data('deg')));
    });
    // for cropping selected section and submit the form to
    // server
    $('#crop-result').on('click', function () {
        $user_avatar.croppie('result', {
            type: 'base64',
            format: 'png'
        }).then(function (cropped_image) {
            $('#user-profile-pic').attr('src', cropped_image);
            $('#user-avatar-data').val(cropped_image);
            $('.user-avatar-form').submit();
            $('#cropAvatarModal').modal('hide');
        })
    });
    // On modal close destroy croppie object, set file input
    // value as nil
    $('#cropAvatarModal').on('hidden.bs.modal', function () {
        $user_avatar.croppie('destroy', {});
        $('#avatar-file-field').val('');
    });
    // reads the file uploaded, convert the file into base64 data
    function readFile(file) {
        let FR = new FileReader();
        FR.addEventListener("load", function (e) {
            // $('#image').attr('src', e.target.result);
            $user_avatar = $('.croppie-area').croppie({
                viewport: {
                    width: 250,
                    height: 250,
                },
                boundary: { width: 400, height: 350 },
                enableOrientation: true,
                enableExif: true
            });
            $user_avatar.croppie('bind', {
                url: e.target.result,
                points: [77,469,280,739]
            }).then(function () {
                $('.cr-slider').attr({'min': 0, 'max': 2.5000});
            });
            $('#cropAvatarModal').modal('show');
        });
        FR.readAsDataURL(file);
    }
});