// For intl-tel-input global settings
'use strict';
import intlTelInput from 'intl-tel-input';
$(document).on('turbolinks:load', function() {
    const phone_input = document.querySelector(".intl-phone-number");
    if(phone_input){
        intlTelInput(phone_input, {
            // allowDropdown: false,
            // autoHideDialCode: false,
            // autoPlaceholder: "off",
            // dropdownContainer: document.body,
            // excludeCountries: ["us"],
            // formatOnDisplay: false,
            // geoIpLookup: function(callback) {
            //   $.get("http://ipinfo.io", function() {}, "jsonp").always(function(resp) {
            //     var countryCode = (resp && resp.country) ? resp.country : "";
            //     callback(countryCode);
            //   });
            // },
            // hiddenInput: "full_number",
            // initialCountry: "auto",
            // localizedCountries: { 'de': 'Deutschland' },
            // nationalMode: false,
            // onlyCountries: ['us', 'gb', 'ch', 'ca', 'do'],
            // placeholderNumberType: "MOBILE",
            preferredCountries: ['us', 'ca']
            // separateDialCode: true,
            //utilsScript: "assets/js/utils.js"
        });
    }
});
