// import core related files here and import this file in packs

// displaying flash messages using iziToast
import iziToast from 'izitoast/dist/js/iziToast';
global.iziToast = iziToast;
import './izitoast_settings'

// prompt user with sweetalert
import Swal from 'sweetalert2/dist/sweetalert2.all'
//window.swal = Swal // set swal on window for sweetify
global.Swal = Swal;

// Pace-Js (page progress indicator)
import '../../vendor/pacejs/pace'
import './pace_settings';

// Jquery validation plugin
import 'jquery-validation/dist/jquery.validate';
import 'jquery-validation/dist/additional-methods';
import "./jquery_validation_defaults";

// intl-tel-input
import './intl_tel_input_settings';

import { submitRemoteFormOrLink } from  './remote_link_and_form';
global.submitRemoteFormOrLink = submitRemoteFormOrLink; // to use in js.erb files (AJAX)

import { coloredPopOver, toolTip, noLinkHref } from './defaults';
global.toolTip = toolTip;
global.coloredPopOver = coloredPopOver;
global.noLinkHref = noLinkHref;
global.toolTip = toolTip; // to use in js.erb files (AJAX)