$(document).on('turbolinks:load', function () {
    let contact_us_form = $('#forgot-pass-form');
    contact_us_form.validate({
        rules: {
            'user[email]': {
                required: true
            }
        },
        messages: {
            'user[email]': {
                required: 'Please enter Email'
            }
        }
    })
});