'use strict';
// tooltip, popover , toast etc..
$(document).on('turbolinks:load', function() {
    //console.log('from application main js')
    $("select[multiple='multiple']").select2({
        placeholder: 'FILTER BY'
    });

    scrollToTop();
    windowScroll();
    signupNextBtn();
    clickFileField();
    jvPropertyImagesCallbacks();
    getBase64OnImageUpload();
    jvDocUploadCallbacks();
    jvPropDocUploadCallbacks();
    setFileName();
    subTypeSelect();
});


const scrollToTop = function () {
    $(".scroll-to-top").on('click', function(){
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    });
}

const windowScroll = function(){
    $(window).scroll(function() {
        if ($(window).scrollTop() > 400) {
            $('nav').addClass('scroll2 navbar-light');
            $('nav').removeClass('scroll1 navbar-dark');
            $('.show-logo-white').css('display', 'none');
            $('.show-logo-dark').css('display', 'block');
            $('#contactus-link').removeClass('slide-white');
            $('#contactus-link').addClass('slide-dark');
        }
        if ($(window).scrollTop() < 401) {
            $('nav').addClass('scroll1 navbar-dark');
            $('nav').removeClass('scroll2 navbar-light');
            $('.show-logo-white').css('display', 'block');
            $('.show-logo-dark').css('display', 'none');
            $('#contactus-link').removeClass('slide-dark');
            $('#contactus-link').addClass('slide-white');
        }

        if ($(window).scrollTop() > 900) {
            $('.scroll-to-top').css('display', 'block');
        } else {
            $('.scroll-to-top').css('display', 'none');
        }
    });
}

function signupNextBtn() {
    $('a[data-toggle="tab"]').on('shown.bs.tab', function (e) {
        let target = $(e.target).attr("href") // activated tab
        let nextBtn = $('#signup-next-btn');
        if (target === '#menu2') {
            $('#signup-submit-btn').removeClass('d-none');
            nextBtn.addClass('d-none');
        } else {
            $('#signup-submit-btn').addClass('d-none');
            nextBtn.removeClass('d-none');
        }
    });

    $('#resend-conf-btn').on('click', function (){
        $('#resend-confirmation-form').submit();
    })

    $('.hjv-file-upload').change(function() {
        // var i = $(this).prev('label').clone();
        var file = $(this)[0].files[0].name;
        $(this).prev('label').find('span.file-name-label').text(file);
    });

    $(".end-user-motive").on('click', function () {
        let realtorForm =  $('.realtor-form');
        let investorForm =  $('.investor-form')
        let userType = $("input[name='user[user_type]']:checked").data('value');
        if(userType === 'investor') {
            investorForm.removeClass('d-none')
            investorForm.find('input').each(function() {
                $(this).removeAttr('disabled')
            });
            investorForm.find('select').each(function() {
                $(this).removeAttr('disabled')
            });

            realtorForm.addClass('d-none')
            realtorForm.find('input').each(function() {
                $(this).attr('disabled', 'disabled')
            });
            realtorForm.find('select').each(function() {
                $(this).attr('disabled', 'disabled')
            });

        } else {
            realtorForm.removeClass('d-none')
            realtorForm.find('input').each(function() {
                $(this).removeAttr('disabled')
            });
            realtorForm.find('select').each(function() {
                $(this).removeAttr('disabled')
            });
            investorForm.addClass('d-none')
            investorForm.find('input').each(function() {
                $(this).attr('disabled', 'disabled')
            });
            investorForm.find('select').each(function() {
                $(this).attr('disabled', 'disabled')
            });
        }
    })
}

const clickFileField = function () {
    $('.pr-image').on('click', function (){
       $(this).parent().find("input[type=file]").click();
    });
}


// products js in orders edit
const jvPropertyImagesCallbacks = function() {
    $('#jv-property-images-nested-form')
        .on('cocoon:before-insert', function(e, property_image_to_be_added) {
            property_image_to_be_added.fadeIn('slow');
            setJvCoverPhoto();
        })
        .on('cocoon:after-insert', function(e, added_property_image) {
            // e.g. set the background of inserted task
            added_property_image.hide();
            added_property_image.fadeIn('slow');
            added_property_image.find("input[type=file]").click();
            getBase64OnImageUpload();
            clickFileField();
            setJvCoverPhoto();

        })
        .on('cocoon:before-remove', function(e, property_image) {
            // allow some time for the animation to complete
            let confirmation = confirm("Are you sure? Please Submit your changes after removing the image");
            if( confirmation === false ){
                e.preventDefault();
            }
            $(this).data('remove-timeout');
            // property_image.fadeOut('slow');
        }).on("cocoon:after-remove", function () {
        });

}

const setJvCoverPhoto = function(){
    $('.jv-radio-cover-photo-index').click(function (){
        if (!$.isNumeric($(this).val())){
            let current_nested_ele = $(this).closest('.nested-fields');
            let prev_nested_ele = current_nested_ele.prevAll('.nested-fields:first')
            let prev_cover_photo_ele = prev_nested_ele.find(':radio')
            let current_index = parseInt(prev_cover_photo_ele.val() ) + 1;
            $(this).val(current_index)
            $(this).attr('id', 'cover_photo_'+ current_index)
        }
    })
}

const getBase64OnImageUpload = function () {
    $('.pr-img-file-input').on('change', function () {
        if (this.files && this.files[0]) {
            let file = this.files[0];
            let previewImage = $(this).parent().find('img')
            let FR = new FileReader();
            FR.addEventListener("load", function (e) {
                previewImage.attr('src', e.target.result);
            });
            FR.readAsDataURL(file);
        } else {
            $(this).parent().parent().remove();
        }
    });
}

const jvDocUploadCallbacks = function() {
    $('.jv-docs-nested-form')
        .on('cocoon:before-insert', function(e, doc_to_be_added) {
            // doc_to_be_added.fadeIn('slow');
        })
        .on('cocoon:after-insert', function(e, added_doc) {
            // // e.g. set the background of inserted task
            // added_doc.hide();
            // added_doc.fadeIn('slow');
            added_doc.find("input[type=file]").click();
            setFileName();

        })
        .on('cocoon:before-remove', function(e, doc) {
            var confirmation = confirm("Are you sure?");
            if( confirmation === false ){
                e.preventDefault();
            }
            // allow some time for the animation to complete
            $(this).data('remove-timeout');
        }).on("cocoon:after-remove", function () {

    });
}

const jvPropDocUploadCallbacks = function() {
    $('.jv-prop-docs-form')
        .on('cocoon:before-insert', function(e, doc_to_be_added) {
            // doc_to_be_added.fadeIn('slow');
            var fileNameField = $('#jv-doc-entity-name')
            var fileName = fileNameField.val();
            // if( fileName === '' ){
            //     fileNameField.addClass('is-invalid');
            //     $('#jv-doc-fn-error').removeClass('hide');
            //     e.preventDefault();
            // } else {
            //     fileNameField.removeClass('is-invalid');
            //     $('#jv-doc-fn-error').addClass('hide');
            // }
        })
        .on('cocoon:after-insert', function(e, added_doc) {
            // // e.g. set the background of inserted task
            // added_doc.hide();
            // added_doc.fadeIn('slow');
            added_doc.find("input[type=file]").click();
            added_doc.hide();
            setFileName();

        })
        .on('cocoon:before-remove', function(e, doc) {
            var confirmation = confirm("Are you sure?");
            if( confirmation === false ){
                e.preventDefault();
            }
            // allow some time for the animation to complete
        }).on("cocoon:after-remove", function () {
            $(this).data('remove-timeout');
    });
}

const setFileName = function () {
    $('.jv-doc-ent-upload').change(function() {
        var fileName1 = $(this)[0].files[0].name;
        var fileNameEntityField = $('#jv-doc-entity-name')
        var fileSubTypeEntity = $('.entity-select')
        if (fileNameEntityField.length !== 0) {
            var docName = fileNameEntityField.val();
            console.log($(this).parent().hasClass('jv-doc-dn-val-ent'));
            $(this).parent().find('input.jv-doc-dn-val-ent').val(docName);
            $(this).parent().parent().parent().show();
            if (docName.length !== 0) {
                fileName1 = docName + ".pdf"
            }
            $('input.jv-doc-dn-val-ent').append('');
        }
        if (fileSubTypeEntity.length !== 0) {
            var docSubType = fileSubTypeEntity.val();
            console.log($(this).parent().hasClass('jv-doc-dn-val-ent-sub'));
            $(this).parent().find('input.jv-doc-dn-val-ent-sub').val(docSubType);
            $(this).parent().parent().parent().show();
            fileName1 = docSubType + " > " + fileName1;
        }
        $(this).parent().find('span').text(fileName1);
        $('#jv-doc-entity-name').val('');
    });
    $('.jv-doc-prop-upload').change(function() {
        var fileName2 = $(this)[0].files[0].name;
        var fileNamePropertyField = $('#jv-doc-property-name')
        var fileSubTypeProperty = $('.property-select')
        if (fileNamePropertyField.length !== 0) {
            var docName = fileNamePropertyField.val();
            $(this).parent().find('input.jv-doc-dn-val-prop').val(docName);
            $(this).parent().parent().parent().show();
            if (docName.length !== 0) {
                fileName2 = docName + ".pdf"
            }
        }
        if (fileSubTypeProperty.length !== 0) {
            var docSubType = fileSubTypeProperty.val();
            $(this).parent().find('input.jv-doc-dn-val-prop-sub').val(docSubType);
            $(this).parent().parent().parent().show();
            fileName2 = docSubType + " > " + fileName2;
        }
        $(this).parent().find('span').text(fileName2);
        $('#jv-doc-property-name').val('');
    });
    $('.jv-doc-jv-upload').change(function() {
        var fileName3 = $(this)[0].files[0].name;
        var fileNameJVField = $('#jv-doc-jv-name')
        var fileSubTypeJV = $('.jv-select')
        if (fileNameJVField.length !== 0) {
            var docName = fileNameJVField.val();
            $(this).parent().find('input.jv-doc-dn-val-jv').val(docName);
            $(this).parent().parent().parent().show();
            if (docName.length !== 0) {
                fileName3 = docName + ".pdf"
            }
        }
        if (fileSubTypeJV.length !== 0) {
            var docSubType = fileSubTypeJV.val();
            $(this).parent().find('input.jv-doc-dn-val-jv-sub').val(docSubType);
            $(this).parent().parent().parent().show();
            fileName3 = docSubType + " > " + fileName3;
        }
        $(this).parent().find('span').text(fileName3);
        $('#jv-doc-jv-name').val('');
    });
}

const subTypeSelect = function () {
    let sub_type_entity_field = $('.entity-select');
    let sub_type_option = ['Articles of Incorporation', 'Registered Agent', 'Operating Agreement', 'Others']
    if(sub_type_entity_field.length) {
        $.each(sub_type_option, function (index, value) {
            let option = $('<option/>');
            option.attr({ 'value': value }).text(value);
            sub_type_entity_field.append(option);
        })
    }
    let sub_type_property_field = $('.property-select');
    sub_type_option = ['Real Estate Purchase Contract', 'Property Inspection', 'Deed', 'Insurance Policy',
        'Title Report', 'Scope of Work', 'Budget', 'Proforma', 'Comps', 'Architectural  Plans', 'Others']
    if(sub_type_property_field.length) {
        $.each(sub_type_option, function (index, value) {
            let option = $('<option/>');
            option.attr({ 'value': value }).text(value);
            sub_type_property_field.append(option);
        })
    }
    let sub_type_jv_field = $('.jv-select');
    sub_type_option = ['JV Agreement', 'Management Agreement', 'General Assignment',
        'Bill of Sale', 'Resolution', 'Communication Plan', 'Others']
    if(sub_type_jv_field.length) {
        $.each(sub_type_option, function (index, value) {
            let option = $('<option/>');
            option.attr({ 'value': value }).text(value);
            sub_type_jv_field.append(option);
        })
    }
}
