$(document).on('turbolinks:load', function () {
    let passwordForm = $('#update-password-form');
    passwordForm.validate({
        rules: {
            'user[password]': {
                required: true
            },
            'user[password_confirmation]': {
                required: true,
                equalTo: '#password-field'
            }
        },
        messages: {
            'user[password]': {
                required: 'Please enter password'
            },
            'user[password_confirmation]': {
                required: 'Please confirmation password',
                equalTo: "Password and Confirmation password doesn't match"
            }
        }
    })
});