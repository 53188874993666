$(document).on('turbolinks:load', function () {
    let ticketForm = $('#user-tickets-form');
    ticketForm.validate({
        rules: {
            'ticket[title]': {
                required: true
            },
            'ticket[description]': {
                required: true
            }
        },
        messages: {
            'ticket[title]': {
                required: 'Please choose category'
            },
            'ticket[description]': {
                required: 'Please enter description'
            }
        }
    })
});