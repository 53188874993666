// Settings override for Jquery-Validation defaults
$.validator.setDefaults({
    ignore: "",
    highlight: function (element) {
        $(element).addClass('is-invalid');
    },
    unhighlight: function (element) {
        $(element).removeClass('is-invalid');
    },
    errorElement: 'div',
    errorClass: 'invalid-feedback',
    errorPlacement: function (error, element) {
        let text_field_placeholder = element.siblings('.form-control-placeholder').first();
        if (element.siblings('.select2').length) {
            error.insertAfter(element.siblings('.select2-container').children('.selection'));
        } else if (element.parent('.input-group').length) {
            error.insertAfter(element.parent());
        } else if (text_field_placeholder.length) {
            // for input text fields insert after label placeholder
            error.insertAfter(text_field_placeholder);
        } else {
            error.insertAfter(element);
        }
    }
});

//Client side format validation methods
$.validator.addMethod("state_accept", function(value, element, param) {
    return this.optional(element) || !!value.match(/^[A-Za-z]+$/);
}, "Only characters are allowed");
$.validator.addMethod("zip_accept", function(value, element, param) {
    return this.optional(element) || !!value.match(/^\d{5}(?:[-\s]\d{4})?$/);
}, "Invalid format for zip code");
$.validator.addMethod("password_accept", function(value, element, param) {
    return this.optional(element) || !!value.match(/^(?=.*[a-zA-Z])(?=.*[0-9]).{6,}$/);
}, "must be at least 6 characters, include one number and one letter.");
$.validator.addMethod("email_format", function(value, element, param) {
    return this.optional(element) || !!value.match(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
    );}, "Please enter email  format.");
$.validator.addMethod("number_accept", function(value, element, param) {
    return this.optional(element) || !!value.match(/^[0-9]$/);
}, "Only digits are allowed.");
