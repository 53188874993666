/*
Note : To Don't timeout the iziToast for server side error messages
1) send response like below from rails controller
=> render json: { errors: errors, izitoast_timeout: false }
2) Or with custom timeout 30_000 milliseconds
=> render json: { errors: errors, izitoast_timeout: 30000 }
3) If izitoast_timeout key is not present in response then defaults to iziToast global settings
 */
$(document).on('turbolinks:load', function() {
    submitRemoteFormOrLink();
});

const submitRemoteFormOrLink = function(){
    // submit remote form
    $("#remote-form, .remote-form").on("ajax:beforeSend", function(event) {
        disableSubmitBtnWithSpinner();
    }).on("ajax:success", function(event) {
        let detail = event.detail;
        let data = detail[0]; //, status = detail[1],  xhr = detail[2];
        // show server side validations in toastr popup
        if (data['success']) {
            // redirect to with Turbolinks on success
            Turbolinks.visit(data['redirect_url']);
        } else {
            let errors = [];
            if (data['error']) {
                errors.push(data['error'])
            } else if (data['errors']) {
                errors = data['errors'];
            }
            if (errors.length) {
                let errors_html = '';
                $.each(errors, function (i, val) {
                    errors_html += (i + 1) + ". ";
                    errors_html += val + '</br>'
                });
                console.log(data['izitoast_timeout'])
                // toastr.error(errors_html, data['message']).css("width", "600px");
                if (data['izitoast_timeout'] || data['izitoast_timeout'] === false) {
                    iziToast.error({ message: errors_html, timeout: data['izitoast_timeout'] });
                }else{
                    iziToast.error({ message: errors_html });
                }
            }
            enableSubmitBtn();
        }
    }).on('ajax:error',function(event){
        let detail = event.detail;
        let full_error = detail[0], error_message = detail[1]; //,  xhr = detail[2];
        iziToast.error({ title: error_message, message: full_error });
        enableSubmitBtn();
    });

    // Remote Links
    $('.remote-link').on("ajax:success", function(event) {
        let detail = event.detail;
        let data = detail[0]; //, status = detail[1],  xhr = detail[2];
        // show server side validations in toastr popup
        if (data['success']) {
            // redirect to with Turbolinks on success
            Turbolinks.visit(data['redirect_url']);
        } else {
            if (data['error']) {
                iziToast.error({ message: data['error'] });
            }else{
                iziToast.error({ message:'Oops something went wrong' });
            }
        }
    }).on('ajax:error',function(event){
        let detail = event.detail;
        let full_error = detail[0], error_message = detail[1]; //,  xhr = detail[2];
        //TODO: remove console.log before production deployment
        console.log('full_error:', full_error);
        iziToast.error({ message: error_message });
    });
};

const disableSubmitBtnWithSpinner = function(){
    let submit_btn = $('.disable-submit-btn');
    submit_btn.prop("disabled", true);
    // add spinner to button
    let btn_name = submit_btn.html();
    submit_btn.html("<span class='spinner-border spinner-border-sm'></span> "+ btn_name);
};
const enableSubmitBtn = function(){
    let submit_btn = $('.disable-submit-btn');
    submit_btn.prop("disabled", false);
    submit_btn.find("span").remove();
};

module.exports = { submitRemoteFormOrLink };