'use strict';

$(document).on('turbolinks:change', function() {
    Pace.options = {
        restartOnRequestAfter: false,
        // Ignore URLs based on a pattern:
        ajax: {
            ignoreURLs: ['some-url-1', 'some-url-2']
        }
    }
  //  Pace.restart();
});

// To make Pace works on Ajax calls
$(document).ajaxStart(function () {
    //Pace.restart()
});

// $(document).on('turbolinks:load', function() {
//     Pace.options = {
//         ajax: false
//     }
// });



// If you don't want it to load on any Ajax request you can set:

/*
Pace.options = {
    ajax: false
}
*/
// You can also use Pace.ignore

/*
Pace.ignore(function(){
    //$.ajax(...)
    console.log('Pace ignore....')
});
*/

// $('.ajax').click(function () {
//     $.ajax({
//         url: '#', success: function (result) {
//             $('.ajax-content').html('<hr>Ajax Request Completed !')
//         }
//     })
// });