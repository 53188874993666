$(document).on('turbolinks:load', function () {
    let userSignUpForm = $('#user-signup-form');
    if (userSignUpForm.length) {
        let nextBtn = $('#signup-next-btn');
        nextBtn.on('click', function () {
            let activeLink = $('#signup-tabs .nav-link.active')
            let nextActiveLink = activeLink.parent().next().children();
            if (activeLink.attr('href') === '#menu1') {
                formValidation();
                if  (userSignUpForm.valid()) {
                    activeLink.parent().find('img').removeClass('d-none');
                    nextActiveLink.click();
                }
            } else {
                activeLink.parent().find('img').removeClass('d-none');
                nextActiveLink.click();
            }
        })
    }
});

function formValidation() {
    let userSignUpForm = $('#user-signup-form');
    userSignUpForm.validate({
        rules: {
            'user[full_name]': {
                required: true
            },
            'user[email]': {
                required: true,
                email_format: true
            },
            'user[password]': {
                required: true
            },
            'user[password_confirmation]': {
                required: true,
                equalTo: '#password-field'
            }
        },
        messages: {
            'user[full_name]': {
                required: 'Please enter Full Name'
            },
            'user[email]': {
                required: 'Please enter Email'
            },
            'user[password]': {
                required: 'Please enter Password'
            },
            'user[password_confirmation]': {
                required: 'Please enter Confirmation password',
                equalTo: "Password and Confirmation password doesn't match"
            }
        }
    })
}