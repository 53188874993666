$(document).on('turbolinks:load', function () {
  let post_form = $('#post-form');
  post_form.validate({
    rules: {
      'post[title]': {
        required: true
      },
      'post[content]': {
        required: true
      }
    },
    messages: {
      'post[title]': {
        required: "can't be blank"
      },
      'post[content]': {
        required: "can't be blank"
      }
    }
  })
});
