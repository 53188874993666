$(document).on('turbolinks:load', function () {
    let loginForm = $('#submit-jv-form');
    loginForm.validate({
        rules: {
            'joint_venture[name]': {
                required: true
            },
            'joint_venture[jv_type]': {
                required: true
            },
            'joint_venture[inv_type]': {
                required: true
            },
            'joint_venture[funding_amount]': {
                required: true,
                // number_accept: true
            },
            'joint_venture[inv_term_months]': {
                required: true,
                // number_accept: true
            },
            'joint_venture[roi_percentage]': {
                required: true,
                // number_accept: true
            },
            'joint_venture[short_description]': {
                required: true
            }
        },
        messages: {
            'joint_venture[name]': {
                required: 'Please enter name'
            },
            'joint_venture[jv_type]': {
                required: 'Please choose property type'
            },
            'joint_venture[inv_type]': {
                required: 'Please choose investment type'
            },
            'joint_venture[funding_amount]': {
                required: 'Please enter funding amount',
                // number_accept: 'Only numbers are allowed'
            },
            'joint_venture[inv_term_months]': {
                required: 'Please enter investment term',
                // number_accept: 'Only numbers are allowed'
            },
            'joint_venture[roi_percentage]': {
                required: 'Please enter projected roi',
                // number_accept: 'Only numbers are allowed'
            },
            'joint_venture[short_description]': {
                required: 'Please enter short description',
            }
        }
    })
});